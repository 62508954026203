<script>
/* COMPONENT DOCUMENT
 * author: liyulong
 * date: 2019/10/08
 * desc: 三级联动 - 省市区地址选择
 */

    // import Vue from 'vue';
    // import {Select, Option} from 'element-ui';
    //
    // Vue.use(Select);
    // Vue.use(Option);

    export default {
        name: 'province-city-district',
        props: {
            address: {
                type: Object, default: () => {
                }
            },
            index: {type: Number, default: 0},
            isArea: {type: Boolean, default: false}
        },

        data() {
            return {
                columns: [
                    {
                        values: [],
                        className: 'column1',
                        defaultIndex: 0
                    },
                    {
                        values: [],
                        className: 'column2',
                        defaultIndex: 0
                    },
                    {
                        values: [],
                        className: 'column3',
                        defaultIndex: 0
                    }
                ],
                address_info: [], // 三级地址信息
                address_show: false, // 地址选择

                provice: '',
                city: '',
                area: '',
                proviceList: [],
                districtList: [],
                areaList: [],
                message: '',
                val: [],
                isFirst: true,
                disabled: true
            };
        },

        methods: {
            // 处理地址信息 去掉一二三层的全部，并默认显示第一层数据
            async disposeAddressInfo() {
                // 获取地址信息
                const api = this.$fetchApi.addressInfo.api;
                const data = await this.$fetchData.fetchPost({}, api);

                if (data.code === '200') {
                    this.address_info = data.result.children || [];
                    this.address_info.shift();
                    this.$set(this.columns[0], 'values', this.address_info);
                }
            },

            /** 根据省市区id查询对应的文字， 且默认展示对应的省市区 */
            getAddressName() {
                let province_index = 0;
                let city_index = 0;
                this.address_info.forEach((item, index) => {
                    if (item.id === this.address.provinceId) {
                        province_index = index;
                        this.provice = item;
                        this.$set(this.columns[0], 'values', this.address_info);
                        // this.$set(this.columns[0], 'defaultIndex', index);
                        return false;
                    }
                });
                if (this.address_info[province_index]) {
                    this.address_info[province_index].children.forEach((item, index) => {
                        if (item.id === this.address.cityId) {
                            city_index = index;
                            this.city = item;
                            this.$set(
                                this.columns[1],
                                'values',
                                this.address_info[province_index].children
                            );
                            // this.$set(this.columns[1], 'defaultIndex', index);
                            return false;
                        }
                    });
                    this.address_info[province_index].children[city_index].children.forEach(
                        (item) => {
                            if (item.id === this.address.districtId) {
                                this.area = item;
                                this.$set(
                                    this.columns[2],
                                    'values',
                                    this.address_info[province_index].children[city_index].children
                                );
                                // this.$set(this.columns[2], 'defaultIndex', index);
                                return false;
                            }
                        }
                    );
                }
            },
            choseProvince(e) {
                const provice = this.columns[0].values;
                for (const index in provice) {
                    if (e.id === provice[index].id) {
                        if (provice[index].children[0].name === '全部') provice[index].children.shift();
                        this.$set(this.columns[1], 'values', provice[index].children);
                        this.$set(this.columns[2], 'values', '');
                        this.city = '';
                        this.area = '';
                        this.val[0] = {
                            id: provice[index].id,
                            name: provice[index].name
                        };
                    }
                }
            },
            // 选市
            choseCity(e) {
                const values = this.columns[1].values;
                for (const index in values) {
                    if (e.id === values[index].id) {
                        this.district = values[index];
                        if (values[index].children[0].name === '全部') values[index].children.shift();
                        this.$set(this.columns[2], 'values', values[index].children);
                        this.area = '';
                        this.val[1] = {
                            id: values[index].id,
                            name: values[index].name
                        };
                    }
                }
                if (this.isArea) {
                    if (this.val.indexOf(this.index) === -1) {
                        this.val.push(this.index);
                    }
                    this.$emit('mAddressInfo', this.val);
                }
            },
            // 选区
            choseBlock(e) {
                const values = this.columns[2].values;
                for (const index in values) {
                    if (e.id === values[index].id) {
                        this.area = values[index];
                        this.val[2] = {
                            id: values[index].id,
                            name: values[index].name
                        };
                    }
                }

                if (this.val.indexOf(this.index) === -1) {
                    this.val.push(this.index);
                }
                this.$emit('mAddressInfo', this.val);
            }
        },

        watch: {
            address: {
                async handler(val) {
                    if (val) {
                        this.disabled = val.disabled;
                        if (this.isFirst) {
                            await this.disposeAddressInfo();
                        }
                        this.provice = '';
                        this.city = '';
                        this.area = '';
                        if (val.provinceId && val.provinceId && val.districtId) {
                            this.getAddressName();
                        }
                    }
                },
                immediate: true,
                deep: true
            }
        }
    };
</script>

<template>
    <div class="province_city_district flex">
        <div class='areaItem'>
            <el-select
                    v-model="provice"
                    @change="choseProvince"
                    value-key="name"
                    :disabled='disabled'
                    placeholder="选择省">
                <el-option
                        v-for="item in columns[0].values"
                        :key="item.id"
                        :label="item.name"
                        :value="item">
                </el-option>
            </el-select>
        </div>
        <div class='areaItem'>
            <el-select
                    v-model="city"
                    @change="choseCity"
                    :disabled='disabled'
                    value-key="name"
                    placeholder="选择市">
                <el-option
                        v-for="item in columns[1].values"
                        :key="item.id"
                        :label="item.name"
                        :value="item">
                </el-option>
            </el-select>
        </div>
        <div class='areaItem'>
            <el-select
                    v-model="area"
                    @change="choseBlock"
                    :disabled='disabled'
                    value-key="name"
                    placeholder="选择区">
                <el-option
                        v-for="item in columns[2].values"
                        :key="item.id"
                        :label="item.name"
                        :value="item">
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<style lang="less" scoped>
    // @import '~@/assets/css/var';

    .province_city_district {
        .areaItem {
            /deep/ .el-input__inner {
                width: 292px;
            }

            /deep/ .el-select {
                width: 167px;
                margin-right: 0px;
            }

            /deep/ .el-input {
                width: 180px;
            }

            /deep/ .el-input__inner {
                width: 160px;
            }
        }
    }
</style>
